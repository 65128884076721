import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import ArticleNav from "../components/nav"

const ArticlesPage = ({ data }) => (
  <div className="page-articles page-article">
    <SEO title="Articles" keywords={[`mark`, `beeson`, `engineering`]}/>
    <ArticleNav/>
    <header>
      <div className="background" style={{ backgroundImage: `url(${data.headerImage.childImageSharp.fluid.src})` }}/>
      <h1>The future of web development</h1>
      <h3 className={"no-bottom"}>
        How to build for the always-connected user, the always-available
        organization, and the always-amazing engineer.
      </h3>
    </header>
    <div className={"article-list"}>
      <div>
        {data.allMarkdownRemark.edges.filter(el => el.node.frontmatter.title.length > 0)
          .map(({ node }) => {
            return (
              <Link to={`/articles${node.fields.slug}`} key={node.id} className={"article-list-article"}>
                <div className={"article-list-image"}
                     style={{ backgroundImage: `url(${node.frontmatter.cover.childImageSharp.fluid.src})` }}/>
                <h2>{node.frontmatter.title}</h2>
                <h4 className={"no-margin"}>{node.frontmatter.subhead}</h4>
                <span>Minutes to read &#x2192; {node.timeToRead}</span>
              </Link>
            )
          })
        }
      </div>
    </div>
    <footer>
      <div>
        <div className="contact">Contact &#x2192;</div>
        <a href="https://twitter.com/markrbeeson" className="action-link">Twitter</a>
        <a href="https://linkedin.com/in/mark242/" className="action-link">LinkedIn</a>
        <a href="https://github.com/m242" className="action-link">GitHub</a>
        <a href="https://gitlab.com/markbeeson" className="action-link">GitLab</a>
        <a href="mailto:mark@beeson.email" className="action-link">Email</a>
      </div>
      <div className={"copyright"}>
        Copyright &#169; Mark Beeson.
      </div>
    </footer>
  </div>
)

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [ frontmatter___date ]}) {
      edges {
        node {
          id
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            date
            datestr: date(formatString: "ddd MMM D, YYYY")
            subhead
            cover {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
    headerImage: file(relativePath: { eq: "legos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ArticlesPage